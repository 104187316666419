import { Controller } from "@hotwired/stimulus"
import {Line} from "@antv/g2plot";

export default class extends Controller {
  static values = { data: Array }

  static targets = ['list']

  async connect() {
    await this.fetchData()
  }

  async fetchData() {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const response = await fetch('/index3_load_data', {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
    })
    if (response.ok) {
      const data = await response.json();
      for(const [key, value] of Object.entries(data)) {
        this.renderItem(key, value)
      }
    } else {
      console.error('Failed to fetch data:', response.status)
    }
  }

  renderItem(k,v) {
    const colWrapper = document.createElement('div')
    colWrapper.className = 'col'
    const statsCard = document.createElement('div')
    statsCard.className = 'card text-center'
    const cardHeader = document.createElement('div')
    cardHeader.className = 'card-header'
    const caseCenter = document.createElement('h5')
    caseCenter.className = 'card-title text-center'
    caseCenter.innerText = k
    cardHeader.appendChild(caseCenter)
    const headerNav = document.createElement('ul')
    headerNav.className = 'nav nav-tabs card-header-tabs'
    headerNav.id = 'myTab'
    const cardBody = document.createElement('div')
    cardBody.className = 'card-body'
    const tabContent = document.createElement('div')
    tabContent.className = 'tab-content'
    Object.keys(v).forEach((k1, i) => {
      const x = v[k1]
      const navItem = document.createElement('li')
      navItem.className = 'nav-item'
      const navLink = document.createElement('a')
      navLink.className = 'nav-link'
      navLink.id = `nav-${k}-${k1}-tab`
      navLink.setAttribute('data-bs-toggle', 'tab')
      navLink.setAttribute('href', `#nav-${k}-${k1}`)
      navLink.innerText = k1
      navItem.appendChild(navLink)
      headerNav.appendChild(navItem)

      const tabPane = document.createElement('div')
      tabPane.className = 'tab-pane fade'
      tabPane.id = `nav-${k}-${k1}`
      const bodyContent = document.createElement('div')
      const chartData = [];
      x.forEach(item => {
        Object.entries(item).forEach(([key, value]) => {
          if (key === 'approved' || key === 'transferred' || key === 'rfe') {
            chartData.push({
              last_action_date: item.last_action_date,
              status: key,
              count: value,
            });
          }
        });
      });
      chartData.sort((a, b) => {
        if (a.last_action_date < b.last_action_date) {
          return -1;
        }
        if (a.last_action_date > b.last_action_date) {
          return 1;
        }
        return 0;
      });
      const chart = new Line(bodyContent, {
        data: chartData,
        xField: 'last_action_date',
        yField: 'count',
        seriesField: 'status',
        xAxis: {
          tickCount: chartData.length,
          label: {
            autoRotate: true,
          }
        },
        smooth: true,
        responsive: true,
        legend: {
          position: 'top',
        },
        });
      chart.render();
      // bodyContent.appendChild(chart)
      // bodyContent.innerText = x.values.A + ' ' + x.values.T + ' ' + x.values.R

      tabPane.appendChild(bodyContent)
      tabContent.appendChild(tabPane)
      if ( i === 0 ) {
        navLink.classList.add('active')
        tabPane.classList.add('show', 'active')
      }
    })
    cardHeader.appendChild(headerNav)
    cardBody.appendChild(tabContent)
    statsCard.appendChild(cardHeader)
    statsCard.appendChild(cardBody)
    colWrapper.appendChild(statsCard)
    this.listTarget.appendChild(colWrapper)
  }
}
