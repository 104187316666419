import consumer from "./consumer"

function subscribeToDiscussionChannel() {
  consumer.subscriptions.create("DiscussionChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("Connected to the discussion channel!");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      const noticeElement = document.getElementById('notice');
      // noticeElement.style.color = 'green';
      noticeElement.innerText = data.message;
      // document.body.prepend(noticeElement);
    }
  });
}

export { subscribeToDiscussionChannel };

