import {Controller} from "@hotwired/stimulus"
import 'datatables.net-bs5'
import 'datatables.net-dt/css/dataTables.dataTables.min.css'
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css'
import jwt from 'jwt-simple';
import moment from 'moment';

export default class extends Controller {
  static targets = ["startCase", "queryRance", "spinner", "output",
    "submitButton", "isOfflineQuery", "selectedCaseType", "selectedCaseStatus",
    "lastActionDate"]

  static dateRegex = /\b(January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}\b/g;

  csrfToken = ''

  connect() {
    this.startCaseTarget.addEventListener('input', this.toUpperCase.bind(this));
    this.csrfToken = document.querySelector("[name='csrf-token']").content;
  }

  toUpperCase(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  async search(event) {
    if (!this.startCaseTarget.checkValidity()) {
      return;
    }
    event.preventDefault();

    // if (!this.startCaseTarget.value) {
    //   alert('Start Case is required.');
    //   return;
    // }

    this.submitButtonTarget.disabled = true;
    this.outputTarget.innerHTML = '';
    if ($.fn.DataTable.isDataTable('#caseTable')) {
      $('#caseTable').DataTable().destroy();
    }// Clear existing content if needed
    this.showSpinner();

    try {
      if (this.isOfflineQueryTarget.checked) {
        await this.offlineQuery();
      } else {
        await this.onlineQuery();
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      this.submitButtonTarget.disabled = false;
      this.hideSpinner();
    }

  }

  generateJwtToken() {
    const secretKey = 'your_secret_key_here';
    const payload = {
      sub: 'UI_USER',
      iss: 'https://egov.uscis.gov',
      id: 'ABC1234567890',
      exp: moment().add(2, 'hours').unix()
    };

    return jwt.encode(payload, secretKey, 'HS512');
  }

  jsonpRequest(url, callbackName) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `${url}?callback=${callbackName}`;
      script.async = true;

      window[callbackName] = (data) => {
        resolve(data);
        document.body.removeChild(script);
        delete window[callbackName];
      };

      script.onerror = (error) => {
        reject(error);
        document.body.removeChild(script);
        delete window[callbackName];
      };

      document.body.appendChild(script);
    });
  }

  async onlineQuery(event) {
    const accessToken = this.generateJwtToken();
    console.log(`accessToken: ${accessToken}`);

    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

    const startCase = this.startCaseTarget.value;
    const range = parseInt(this.queryRanceTarget.value, 10);
    const prefix = startCase.slice(0, 3);
    const startNumber = parseInt(startCase.slice(3), 10);

    const table = document.createElement('table');
    table.className = 'table table-bordered table-striped';
    table.id = 'caseTable';
    const thead = document.createElement('thead');
    const headerRow = document.createElement('tr');
    ['Case Number', 'Case Type', 'Action Code Text',
      'Last Action Date'].forEach(headerText => {
      const th = document.createElement('th');
      th.textContent = headerText;
      headerRow.appendChild(th);
    });
    thead.append(headerRow);
    table.appendChild(thead);
    const tbody = document.createElement('tbody');
    table.appendChild(tbody);
    this.outputTarget.appendChild(table);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append('User-Agent', 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3');
    myHeaders.append('Referer', 'https://egov.uscis.gov');
    myHeaders.append('Origin', 'https://egov.uscis.gov');
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    myHeaders.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');
    myHeaders.append("accept", "*/*");
    myHeaders.append("accept-language", "en-US,en;q=0.9,fr;q=0.8");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      credentials: 'include'
    };

    for (let i = 0; i < range; i++) {
      try {
        const caseNumber = `${prefix}${(startNumber + i).toString().padStart(10,
            '0')}`;
        const response = await fetch(`https://egov.uscis.gov/csol-api/case-statuses/${caseNumber}`, requestOptions);

        if (response.ok) {
          const caseData = await response.json();
          if (caseData.CaseStatusResponse.isValid === true) {
            const detailsEng = caseData.CaseStatusResponse.detailsEng;
            const desc = detailsEng.actionCodeDesc;
            const dateStrings = desc.match(this.constructor.dateRegex);
            const dates = dateStrings ? dateStrings.map(dateStr => new Date(dateStr)) : [];
            const lastActionDate = dates.length > 0
                ? `${dates[0].getFullYear()}-${(dates[0].getMonth() + 1).toString().padStart(2, '0')}-${(dates[0].getDate()).toString().padStart(2, '0')}`
                : '';

            const row = document.createElement('tr');
            [caseNumber, detailsEng.formNum, detailsEng.actionCodeText, lastActionDate].forEach(cellText => {
              const td = document.createElement('td');
              td.textContent = cellText;
              row.appendChild(td);
            });
            tbody.appendChild(row);
          }
        } else {
          console.error(`Failed to get case status for ${caseNumber}`);
        }
      } catch (error) {
        console.error('Error:');
      }
    }
    // Initialize DataTables
    $('#caseTable').DataTable({
      layout: {
        topStart: {
          buttons: ['copy', 'csv']
        }
      }
    })
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }

  async offlineQuery() {
    const response = await fetch('/uscis_cases/search3_offline_query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify(
          {
            case_number: this.startCaseTarget.value,
            range: this.queryRanceTarget.value,
            case_type: this.selectedCaseTypeTarget.value,
            case_status: this.selectedCaseStatusTarget.value,
            last_action_date: this.lastActionDateTarget.value
          })
    })

    if (response.ok) {
      const cases = await response.json()

      const table = document.createElement('table');
      table.className = 'table table-bordered table-striped';
      table.id = 'caseTable';
      const thead = document.createElement('thead');
      const headerRow = document.createElement('tr');
      ['Case Number', 'Case Type', 'Action Code Text',
        'Last Action Date'].forEach(headerText => {
        const th = document.createElement('th');
        th.textContent = headerText;
        headerRow.appendChild(th);
      });
      thead.append(headerRow);
      table.appendChild(thead);
      const tbody = document.createElement('tbody');
      table.appendChild(tbody);
      this.outputTarget.appendChild(table);

      console.log(`cases: ${cases}`);

      for (let i = 0; i < cases.length; i++) {
        const caseNumber = cases[i].case_number;
        const caseType = cases[i].case_type;
        const actionCodeText = cases[i].status_full;
        const lastActionDate = cases[i].last_action_date;
        const row = document.createElement('tr');
        [caseNumber, caseType, actionCodeText, lastActionDate].forEach(
            cellText => {
              const td = document.createElement('td');
              td.textContent = cellText;
              row.appendChild(td);
            });
        tbody.appendChild(row);
      }
      // Initialize DataTables
      $('#caseTable').DataTable({
        layout: {
          topStart: {
            buttons: ['copy', 'csv']
          }
        }
      });

    } else {
      console.error('Failed to query offline:', response.status)
    }
  }
}
