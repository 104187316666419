import { Controller } from "@hotwired/stimulus"
import {Column} from "@antv/g2plot";

export default class extends Controller {
  static targets = [ "case_center", "case_type", "from_status", "spinner", "submitButton", "output" ]

  caseList = []

  connect() {
    console.log(`initialize case move controller`)
    this.caseList = JSON.parse(this.data.get("case-list"))
    console.log(`Case List: ${this.caseList.length}`)
  }

  query() {
    event.preventDefault();
    this.submitButtonTarget.disabled = true;
    this.outputTarget.innerHTML = ''; // Clear existing content if needed
    this.showSpinner();
    const caseCenter = this.case_centerTarget.value.trim()
    const caseType = this.case_typeTarget.value.trim()
    const fromStatus = this.from_statusTarget.value.trim()
    console.log(`Case Center: ${caseCenter}, Case Type: ${caseType}`)
    const filteredCaseList = this.caseList.filter(item => item.case_center === caseCenter && item.case_type === caseType && item.hist_status_short === fromStatus)

    const countByRangeKey = filteredCaseList.reduce((acc, item) => {
      acc[item.range_key] = (acc[item.range_key] || 0) + 1;
      return acc;
    }, {});

    const data = Object.keys(countByRangeKey).map(key => ({
      range_key: key,
      count: countByRangeKey[key]
    }));

    const columnChart = new Column(this.outputTarget, {
      data,
      forceFit: true,
      padding: 'auto',
      xField: 'range_key',
      yField: 'count',
      colorField: 'range_key',
      slider: {
        start: 0,
        end: 1,
      },
      responsive: true,
      legend: {
        position: 'top',
      },
      xAxis: {
        tickCount: data.length,
        label: {
          autoRotate: true,
        }
      },
    });

    columnChart.render();
    this.hideSpinner();
    this.submitButtonTarget.disabled = false;
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }

}