import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputGrid"];

  connect() {
    this.generateWord();
  }

  generateWord() {
    // Send an AJAX request to the server to get a new random word
    fetch("/games/random_word")
    .then((response) => response.json())
    .then((data) => {
      this.word = data.value;
      const inputs = this.element.querySelectorAll('.grid-input');
      inputs.forEach((input) => {
        input.value = '';
        input.classList.remove("match");
        input.classList.remove("present");
        input.classList.remove("absent");
        input.disabled = false;
        input.setAttribute('autocapitalize', 'off');
      });
    });
  }

  focusNext(event) {
    const inputElements = event.target.parentElement.querySelectorAll(".grid-input");
    console.log(inputElements);
    const currentElement = event.target;
    console.log(currentElement.value);
    const currentIndex = Array.from(inputElements).indexOf(currentElement);
    console.log(currentIndex);
    if (currentIndex < inputElements.length - 1) {
      const nextElement = inputElements[currentIndex + 1];
      nextElement.focus();
    } else if (
        currentIndex === inputElements.length - 1 &&
        (currentIndex + 1) % 5 === 0
    ) {
      this.guess(event);
      const nextRow = currentElement.parentElement.nextElementSibling;
      if (nextRow) {
        const nextElement = nextRow.querySelector(".grid-input");
        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  }

  guess(event) {
    event.preventDefault();
    const guess = Array.from(
        event.target.parentElement.querySelectorAll(".grid-input")
    )
    .map((input) => input.value.trim())
    .join("").toLowerCase();
    const word = this.word.toLowerCase();
    const result = [];

    // Check if the guessed word matches the actual word
    for (let i = 0; i < word.length; i++) {
      if (word[i] === guess[i]) {
        result.push("C");
      } else if (word.includes(guess[i])) {
        result.push("O");
      } else {
        result.push("X");
      }
    }
    if (guess) {
      // const listItem = document.createElement("li");
      // listItem.textContent = result.join("");
      // this.guessListTarget.appendChild(listItem);
      // this.resetInputGrid();
      this.disableInputGrid(event, result);
    }
  }

  disableInputGrid(event, result) {
    event.target.parentElement
    .querySelectorAll(".grid-input")
    .forEach((input, index) => {
      input.disabled = true;
      // input.classList.add("disabled");
      const isMatch = result[index] === "C";
      const isPresent = result[index] === "O";
      input.classList.toggle("match", isMatch);
      input.classList.toggle("present", isPresent);
      input.classList.toggle("absent", !isMatch && !isPresent);
    });
  }

  resetInputGrid() {
    this.inputGridTarget
    .querySelectorAll(".grid-input")
    .forEach((input) => (input.value = ""));
    this.inputGridTarget.querySelector(".grid-input").focus();
  }

  //   guess(event) {
  //     event.preventDefault();
  //     const guess = this.inputTarget.value.trim().toLowerCase();
  //     const word = this.word.toLowerCase();
  //     const result = [];

  //     // Check if the guessed word matches the actual word
  //     for (let i = 0; i < word.length; i++) {
  //       if (word[i] === guess[i]) {
  //         result.push('C');
  //       } else if (word.includes(guess[i])) {
  //         result.push('O');
  //       } else {
  //         result.push('X');
  //       }
  //     }

  //     if (guess) {
  //       const listItem = document.createElement('li');
  //       listItem.textContent = result.join('');
  //       this.guessListTarget.appendChild(listItem);
  //       this.inputTarget.value = '';
  //     }
  //   }
}
