import { Controller } from "@hotwired/stimulus"
import {Line} from "@antv/g2plot";

export default class extends Controller {
  static values = { data: Array }

  static dataX = {}
  static dataX7d = {}
  static allCharts = {}

  static targets = ['list']

  async connect() {
    await this.fetchData()
  }

  async fetchData() {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const response = await fetch('/index4_load_data', {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
    })
    if (response.ok) {
      const data = await response.json();
      this.dataX = data['data'];
      this.dataX7d = data['data7d'];
      const allChartsTemp = {}
      for(const [key, value] of Object.entries(this.dataX)) {
        this.renderItem(key, value, allChartsTemp)
      }
      this.allCharts = allChartsTemp
    } else {
      console.error('Failed to fetch data:', response.status)
    }
  }

  renderItem(k,v, allChartsTemp) {
    const colWrapper = document.createElement('div')
    colWrapper.className = 'col'
    const statsCard = document.createElement('div')
    statsCard.className = 'card text-center'
    const cardHeader = document.createElement('div')
    cardHeader.className = 'card-header'
    const caseCenter = document.createElement('h5')
    caseCenter.className = 'card-title text-center'
    caseCenter.innerText = k
    cardHeader.appendChild(caseCenter)
    const headerNav = document.createElement('ul')
    headerNav.className = 'nav nav-tabs card-header-tabs'
    headerNav.id = 'myTab'
    const cardBody = document.createElement('div')
    cardBody.className = 'card-body'
    const tabContent = document.createElement('div')
    tabContent.className = 'tab-content'
    const statusMap = {}
    Object.keys(v).forEach((k1, i) => {
      const x = v[k1]
      const navItem = document.createElement('li')
      navItem.className = 'nav-item'
      const navLink = document.createElement('a')
      navLink.className = 'nav-link'
      navLink.id = `nav-${k}-${k1}-tab`
      navLink.setAttribute('data-bs-toggle', 'tab')
      navLink.setAttribute('href', `#nav-${k}-${k1}`)
      navLink.innerText = k1
      navItem.appendChild(navLink)
      headerNav.appendChild(navItem)

      const tabPane = document.createElement('div')
      tabPane.className = 'tab-pane fade'
      tabPane.id = `nav-${k}-${k1}`
      const bodyContent = document.createElement('div')
      bodyContent.id = `chart-${k}-${k1}`
      const chartData = [];
      x.forEach(item => {
        Object.entries(item).forEach(([key, value]) => {
          if (key === 'approved') {
            chartData.push({
              last_action_date: item.last_action_date,
              status: key,
              count: value,
            });
            if (this.dataX7d.hasOwnProperty(k) && this.dataX7d[k].hasOwnProperty(k1) && this.dataX7d[k][k1].length > 0) {
              // console.log("dataX7d", this.dataX7d[k][k1])
              this.dataX7d[k][k1].forEach(item7d => {
                if ((Date.parse(item.last_action_date) - Date.parse(item7d['last_action_date'])) / (1000 * 3600 * 24) === 7) {
                  chartData.push({
                    last_action_date: item.last_action_date,
                    status: 'approved-7d',
                    count: item7d['approved']
                  });
                }
              })
            }
          }
          if (statusMap.hasOwnProperty(`${k}-${k1}-${key}`)) {
            statusMap[`${k}-${k1}-${key}`] = statusMap[`${k}-${k1}-${key}`] + value
          } else {
            statusMap[`${k}-${k1}-${key}`] = value
          }
        });
      });
      chartData.sort((a, b) => {
        if (a.last_action_date < b.last_action_date) {
          return -1;
        }
        if (a.last_action_date > b.last_action_date) {
          return 1;
        }
        return 0;
      });
      const chart = new Line(bodyContent, {
        data: chartData,
        xField: 'last_action_date',
        yField: 'count',
        seriesField: 'status',
        xAxis: {
          tickCount: chartData.length,
          label: {
            autoRotate: true,
          }
        },
        smooth: true,
        responsive: true,
        legend: {
          position: 'top',
        },
        connectNulls: true,
        });
      chart.render();
      allChartsTemp[`chart-${k}-${k1}`] = chart
      // bodyContent.appendChild(chart)
      // bodyContent.innerText = x.values.A + ' ' + x.values.T + ' ' + x.values.R
      const chartSwitcher = document.createElement('div')
      chartSwitcher.className = 'row justify-content-evenly'
      const statuesArray = ['approved', 'transferred', 'rfe']
      statuesArray.forEach((status, i) => {
        if (statusMap[`${k}-${k1}-${status}`] > 0) {
          const chartSwitcherLabel = document.createElement('div')
          chartSwitcherLabel.className = 'col-3 border'
          if (status === 'approved') {
            chartSwitcherLabel.classList.add('bg-success')
          }
          chartSwitcherLabel.innerHTML = status + '<br />' + statusMap[`${k}-${k1}-${status}`]
          chartSwitcherLabel.setAttribute('data-action',
              'click->index4#updateChartDate')
          chartSwitcher.appendChild(chartSwitcherLabel)
        }
      })

      tabPane.appendChild(chartSwitcher)

      tabPane.appendChild(bodyContent)
      tabContent.appendChild(tabPane)
      if ( i === 0 ) {
        navLink.classList.add('active')
        tabPane.classList.add('show', 'active')
      }
    })

    cardHeader.appendChild(headerNav)
    cardBody.appendChild(tabContent)
    statsCard.appendChild(cardHeader)
    statsCard.appendChild(cardBody)
    colWrapper.appendChild(statsCard)
    this.listTarget.appendChild(colWrapper)
  }

  updateChartDate(event) {
    const status = event.target.innerText.split('\n')[0].toLowerCase()
    event.target.parentElement.querySelectorAll('.bg-success').forEach((item) => {
      item.classList.remove('bg-success')
    })
    // event.target.parentElement.childList.forEach((item) => {
    //   if (item !== event.target) {
    //     item.classList.remove('bg-success')
    //   }
    // })
    event.target.classList.add('bg-success')
    const caseType = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.card-header .card-title').innerText
    const caseCenter = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.card-header .card-header-tabs .active').innerText
    // console.log("clicked", status)
    // console.log("dataX", this.dataX[caseType])
    // console.log("event target", caseType)

    const data = this.dataX[caseType][caseCenter]
    const chartData = [];
    data.forEach(item => {
      Object.entries(item).forEach(([key, value]) => {
        if (key === status) {
          chartData.push({
            last_action_date: item.last_action_date,
            status: key,
            count: value,
          });
          if (this.dataX7d.hasOwnProperty(caseType) && this.dataX7d[caseType].hasOwnProperty(caseCenter) && this.dataX7d[caseType][caseCenter].length > 0) {
            // console.log("dataX7d", this.dataX7d[k][k1])
            this.dataX7d[caseType][caseCenter].forEach(item7d => {
              if ((Date.parse(item.last_action_date) - Date.parse(item7d['last_action_date'])) / (1000 * 3600 * 24) === 7) {
                chartData.push({
                  last_action_date: item.last_action_date,
                  status: `${status}-7d`,
                  count: item7d[status]
                });
              }
            })
          }
        }
      });
    });
    chartData.sort((a,b) => (a.last_action_date > b.last_action_date) ? 1 : ((b.last_action_date > a.last_action_date) ? -1 : 0))
    this.allCharts[`chart-${caseType}-${caseCenter}`].changeData(chartData)
  }
}
