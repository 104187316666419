import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['list', 'input']

  csrfToken = ''

  async connect() {
    this.csrfToken = document.querySelector("[name='csrf-token']").content
    await this.fetchTodos()
  }

  async clearAll() {
    const response = await fetch('/games/todos/delete_todos', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    if (response.ok) {
      const todo = await response.json()
      this.listTarget.innerHTML = ''
    } else {
      console.error('Failed to clear todos: ', response.status)
    }
  }

  async addTodo() {
    const title = this.inputTarget.value.trim()
    console.log(title)
    if (title !== '') {
      const response = await fetch('/games/todos/create_todos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({ title })
      })
      if (response.ok) {
        const todo = await response.json()
        this.renderTodoItem(todo)
        this.inputTarget.value = ''
      } else {
        console.error('Failed to add todo:', response.status)
      }
    }
  }

  async toggleCompletion(event) {
    const checkbox = event.target
    const todoId = checkbox.dataset.id

    const response = await fetch(`/games/todos/${todoId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({ completed: checkbox.checked })
    })
    if (!response.ok) {
      console.error('Failed to update todo completion status:', response.status)
      checkbox.checked = !checkbox.checked // Revert the checkbox state if there's an error
    }
  }

  async fetchTodos() {
    const response = await fetch('/games/todos/list')
    if (response.ok) {
      const todos = await response.json()
      todos.forEach(todo => {
        this.renderTodoItem(todo)
      })
    } else {
      console.error('Failed to fetch todos:', response.status)
    }
  }

  renderTodoItem(todo) {
    const todoItem = document.createElement('li')
    const checkbox = document.createElement('input')
    checkbox.type = 'checkbox'
    checkbox.checked = todo.completed
    checkbox.dataset.id = todo.id
    checkbox.addEventListener('change', this.toggleCompletion.bind(this))
    const title = document.createTextNode(todo.title)
    todoItem.appendChild(checkbox)
    todoItem.appendChild(document.createTextNode(' '))
    todoItem.appendChild(title)
    todoItem.className = 'list-group-item'
    todoItem.style = 'padding: 0.5rem 0.5rem !important;'
    this.listTarget.prepend(todoItem)
  }
}
