import { Controller } from "@hotwired/stimulus"
import {Line, Pie, measureTextWidth, Column} from "@antv/g2plot";

export default class extends Controller {
  static values = { history: Array, overall: Object, rangekey: Array, fiscalyear: Object }

  static targets = ['history', 'overall', 'rangekey', 'fiscalyear']

  connect() {
    this.renderOverallChart()
    this.renderHistoryChart()
    this.renderRangekeyChart()
    this.renderFiscalyearChart()
  }

  renderFiscalyearChart() {
    // const colWrapper = document.createElement('div')
    // colWrapper.className = 'col'
    const statsCard = document.createElement('div')
    statsCard.className = 'card text-center border-0'
    const cardHeader = document.createElement('div')
    cardHeader.className = 'card-header'
    // const caseCenter = document.createElement('h5')
    // caseCenter.className = 'card-title text-center'
    // caseCenter.innerText = k
    // cardHeader.appendChild(caseCenter)
    const headerNav = document.createElement('ul')
    headerNav.className = 'nav nav-tabs card-header-tabs'
    headerNav.id = 'myTab'
    const cardBody = document.createElement('div')
    cardBody.className = 'card-body'
    const tabContent = document.createElement('div')
    tabContent.className = 'tab-content'
    const v = this.fiscalyearValue
    Object.keys(v).forEach((k1, i) => {
      const x = v[k1]
      const navItem = document.createElement('li')
      navItem.className = 'nav-item'
      const navLink = document.createElement('a')
      navLink.className = 'nav-link'
      navLink.id = `nav-${k1}-tab`
      navLink.setAttribute('data-bs-toggle', 'tab')
      navLink.setAttribute('href', `#nav-${k1}`)
      navLink.innerText = k1
      navItem.appendChild(navLink)
      headerNav.appendChild(navItem)

      const tabPane = document.createElement('div')
      tabPane.className = 'tab-pane fade'
      tabPane.id = `nav-${k1}`
      const bodyContent = document.createElement('div')

      const stackedColumnPlot = new Pie(bodyContent, {
        data: x,
        angleField: 'case_count',
        colorField: 'case_status',
        radius: 1,
        innerRadius: 0.64,
        meta: {
          value: {
            formatter: (v) => `${v} ¥`,
          },
        },
        label: {
          type: 'inner',
          offset: '-50%',
          style: {
            textAlign: 'center',
            fontSize: 14,
          },
          autoRotate: false,
          content: '{value}',
        },
        statistic: {
          title: {
            offsetY: -4,
            customHtml: (container, view, datum) => {
              const { width, height } = container.getBoundingClientRect();
              const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
              const text = datum ? datum.case_status : 'Total';
              return this.renderStatistic(d, text, { fontSize: 28 });
            },
          },
          content: {
            offsetY: 4,
            style: {
              fontSize: '32px',
            },
            customHtml: (container, view, datum, data) => {
              const { width } = container.getBoundingClientRect();

              const text = datum ? `${datum.case_count}` : `${data.reduce((r, d) => r + d.case_count, 0)}`;
              return this.renderStatistic(width, text, { fontSize: 32 });
            },
          },
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
      });
      stackedColumnPlot.render();
      // bodyContent.appendChild(chart)
      // bodyContent.innerText = x.values.A + ' ' + x.values.T + ' ' + x.values.R

      tabPane.appendChild(bodyContent)
      tabContent.appendChild(tabPane)
      if ( i === 0 ) {
        navLink.classList.add('active')
        tabPane.classList.add('show', 'active')
      }
    })
    cardHeader.appendChild(headerNav)
    cardBody.appendChild(tabContent)
    statsCard.appendChild(cardBody)
    statsCard.appendChild(cardHeader)
    // colWrapper.appendChild(statsCard)
    this.fiscalyearTarget.appendChild(statsCard)
  }

  renderHistoryChart() {
    const chartData = []
      this.historyValue.forEach(item => {
        Object.entries(item).forEach(([key, value]) => {
          if (key === 'approved' || key === 'transferred' || key === 'rfe') {
            chartData.push({
              last_action_date: item.last_action_date,
              status: key,
              count: value,
            });
          }
        });
      });
      chartData.sort((a, b) => {
        if (a.last_action_date < b.last_action_date) {
          return -1;
        }
        if (a.last_action_date > b.last_action_date) {
          return 1;
        }
        return 0;
      });
      const chart = new Column(this.historyTarget, {
        data: chartData,
        xField: 'last_action_date',
        yField: 'count',
        seriesField: 'status',
        isGroup: true,
        xAxis: {
          tickCount: chartData.length,
          label: {
            autoRotate: true,
          }
        },
        label: {
          position: 'top', // 'top', 'middle', 'bottom'
          layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
          ],
        },
        smooth: true,
        responsive: true,
        legend: {
          position: 'top',
        },
        });
      chart.render();
  }

  renderRangekeyChart() {
    const stackedColumnPlot = new Column(this.rangekeyTarget, {
      data: this.rangekeyValue,
      xField: 'range_key',
      yField: 'case_count',
      slider: {
        start: 0,
        end: 1,
      },
      seriesField: 'case_status',
      isStack: true,
      xAxis: {
        tickCount: this.rangekeyValue.length,
        label: {
          autoRotate: true,
        }
      },
      smooth: true,
      responsive: true,
      legend: {
        position: 'top',
      },
    })
    stackedColumnPlot.render()
  }

  renderOverallChart() {
    const overallData = []
    Object.entries(this.overallValue).forEach(([key, value]) => {
      overallData.push({
        status: key,
        count: value,
      });
    });
    const statsCard = document.createElement('div')
    statsCard.className = 'card text-center border-0'
    const cardBody = document.createElement('div')
    cardBody.className = 'card-body'
    const stackedColumnPlot = new Pie(cardBody, {
      data: overallData,
      angleField: 'count',
      colorField: 'status',
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v) => `${v} ¥`,
        },
      },
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
        autoRotate: false,
        content: '{value}',
      },
      statistic: {
        title: {
          offsetY: -4,
          customHtml: (container, view, datum) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            const text = datum ? datum.status : 'Total';
            return this.renderStatistic(d, text, { fontSize: 28 });
          },
        },
        content: {
          offsetY: 4,
          style: {
            fontSize: '32px',
          },
          customHtml: (container, view, datum, data) => {
            const { width } = container.getBoundingClientRect();

            const text = datum ? `${datum.count}` : `${data.reduce((r, d) => r + d.count, 0)}`;
            return this.renderStatistic(width, text, { fontSize: 32 });
          },
        },
      },
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
    });
    stackedColumnPlot.render()

    statsCard.appendChild(cardBody)
    this.overallTarget.appendChild(statsCard)
  }


  renderStatistic(containerWidth, text, style) {
    const textWidth = measureTextWidth(text, style);
    const textHeight = style.lineHeight || style.fontSize
    const R = containerWidth / 2;
    // r^2 = (w / 2)^2 + (h - offsetY)^2
    let scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }
    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};word-wrap: break-word;white-space: pre-wrap;">${text}</div>`;
  }
}
