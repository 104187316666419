import { Controller } from "@hotwired/stimulus"
import {DualAxes} from "@antv/g2plot";


export default class extends Controller {
  static targets = [ "input", "output", "spinner", "submitButton" ]

  connect() {
    this.inputTarget.addEventListener('input', this.toUpperCase.bind(this));
  }

  toUpperCase(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  greet() {
    this.outputTarget.textContent = `Hello, ${this.inputTarget.value}!`
  }

  async query() {
    if (!this.inputTarget.checkValidity()) { return; }
    event.preventDefault();
    this.submitButtonTarget.disabled = true;
    this.outputTarget.innerHTML = ''; // Clear existing content if needed
    this.showSpinner();
    Pace.start();
    const rangeKey = this.inputTarget.value.trim()
    console.log(`Range Key: ${rangeKey}`)
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const response = await fetch('/uscis_cases/range_movements_query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ rangeKey })
    }).finally(() => {
      this.submitButtonTarget.disabled = false; // Re-enable the submit button
      Pace.stop();
      this.hideSpinner();
    });

    if (response.ok) {
      const data = await response.json()
      if (Object.keys(data).length === 0) {
        const alertElement = document.createElement('div');
        alertElement.className = 'alert alert-warning alert-dismissible';
        alertElement.setAttribute('role', 'alert');
        alertElement.innerHTML = `Can't get data for range <strong>${this.inputTarget.value}</strong>.<br>Please request to add the range key.`;
        const closeButton = document.createElement('button');
        closeButton.className = 'btn-close';
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('data-bs-dismiss', 'alert');
        closeButton.setAttribute('aria-label', 'Close');
        alertElement.append(closeButton);
        this.outputTarget.innerHTML = ''; // Clear existing content if needed
        this.outputTarget.appendChild(alertElement);
      } else {
        this.renderChart(data)
      }
    } else {
      this.outputTarget.textContent = `Can't get data for range ${this.inputTarget.value}.`
    }
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }

  renderChart(data) {
    const totalCountByDate = data.reduce((acc, curr) => {
      acc[curr.last_action_date] = (acc[curr.last_action_date] || 0) + curr.count;
      return acc;
    }, {});

    const totalCountData = Object.entries(totalCountByDate).map(([date, count]) => ({
      last_action_date: date,
      total: count,
    }));

    const output = this.outputTarget;
    // Step 1: Create and style the title element
    const titleElement = document.createElement('div');
    titleElement.innerText = this.inputTarget.value; // Replace with your actual title
    titleElement.style.textAlign = 'center';
    titleElement.style.fontSize = '24px';
    titleElement.style.fontWeight = 'bold';
    titleElement.style.color = '#444';
    titleElement.style.marginBottom = '10px'; // Adjust spacing as needed

    output.appendChild(titleElement);

    const chartContainer = document.createElement('div'); // Replace with your actual container ID

    output.appendChild(chartContainer);

    const line = new DualAxes(chartContainer, {
      data: [data, totalCountData],
      padding: 'auto',
      xField: 'last_action_date',
      yField: ['count', 'total'],
      geometryOptions: [
        {
          geometry: 'column',
          isGroup: true,
          seriesField: 'case_type',
        },
        {
          geometry: 'line',
          lineStyle: {
            lineWidth: 2,
          },
        },
      ],
    });

    line.render();
  }
}
