import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    // static values = { center: String }
    static targets = [ "form" ]


    onPostSuccess(event) {
        console.log("success!");
    }

    search() {
        // clearTimeout(this.timeout)
        // this.timeout = setTimeout(() => {
            Rails.fire(this.formTarget, 'submit')
        // }, 200)
    }
}
