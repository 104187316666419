import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = [ "quote", "author" ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.urlValue)
        .then(response => response.json())
        .then(data => { this.quoteTarget.textContent = data.quote; this.authorTarget.textContent = '- ' + data.author.split(",")[0]; })
  }
}
