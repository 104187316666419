// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "js.cookie"
import "chartkick"
import "chartkick/chart.js"
// import "chartjs-plugin-labels"
import "@popperjs/core"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap;

import $ from 'jquery'
window._myjQuery = $;
window.$ = $;

import "smartwizard/dist/css/smart_wizard_all.css";
import smartWizard from 'smartwizard';

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "@hotwired/turbo-rails"
Turbo.session.drive = false

import "lightgallery.js/dist/css/lightgallery.min.css"
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "trix"
import "@rails/actiontext"
import "datatables.net"
import "datatables.net-dt"
import "datatables.net-bs5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"

window.Cookies = require("js-cookie")

window.G2Plot = require("@antv/g2plot")

import "sharer.js"

import "bootswatch/dist/cosmo/bootstrap.min.css"


document.addEventListener("turbo:load", function() {
  initializeTooltips();
});

document.addEventListener("turbo:render", function() {
  initializeTooltips();
});

document.addEventListener("turbo:frame-render", function() {
  initializeTooltips();
});

document.addEventListener("turbo:frame-load", function() {
  initializeTooltips();
});

document.addEventListener("turbo:before-stream-render", function() {
  initializeTooltips();
});

Turbo.StreamActions.reinitializeTooltips = function () {
  initializeTooltips();
};

function initializeTooltips() {
  // Dispose of existing tooltips to prevent duplicates
  var existingTooltips = [].slice.call(document.querySelectorAll('.tooltip'));
  existingTooltips.forEach(function(tooltipEl) {
    let tooltipInstance = bootstrap.Tooltip.getInstance(tooltipEl);
    if (tooltipInstance) {
      tooltipInstance.dispose();
    }
  });

  // Initialize tooltips for elements with data-bs-toggle="tooltip"
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.forEach(function(tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

// // Use MutationObserver to detect changes in the DOM from Turbo Stream updates
// const observer = new MutationObserver(function(mutations) {
//   mutations.forEach(function(mutation) {
//     if (mutation.addedNodes.length) {
//       initializeTooltips();
//     }
//   });
// });
//
// document.addEventListener('DOMContentLoaded', (event) => {
//   // Your script here
//   observer.observe(document.body, {
//     childList: true,
//     subtree: true
//   });
// });

document.addEventListener('DOMContentLoaded', function() {
  import('@vaadin/multi-select-combo-box')
});