import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["filter", "column"]

    // connect() {
    //     $("#search_result_table").DataTable({
    //         order: [[1, "asc"]],
    //         buttons: [
    //             'copy', 'csv', 'excel', 'pdf', 'print'
    //         ]
    //     })
    // }

    filter() {
        this.applyFilter()
    }

    applyFilter() {
        const filterValue = $(this.filterTarget).val();
        if (filterValue.trim().length)
            this.filterRows(filterValue)
    }

    filterRows(filterValue) {
        const valueWithSpace = filterValue.replace(/\s/g, '\xa0');
        $.expr[':'].contains = $.expr.createPseudo((arg) => {
            return (elem) => {
                return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0
            }
        })
        $('tbody tr').hide()
        $("tr td:contains('" + valueWithSpace + "')").parent().show()
        if (valueWithSpace.toString() === "all") {
            $('tbody tr').show()
        }

                // $("#tbody tr").filter(function() {
                //     $(this).toggle($(this).text().toLowerCase().indexOf(valueWithSpace.toString().toLowerCase()) > -1)
                // });
    }

    sort() {
        const th = event.target
        const tr = th.closest('tr')
        const idx = Array.from(tr.children).indexOf(th);
        const table = $(this.columnTarget).parents('table').eq(0)
        let rows = table.find('tr:gt(0)').toArray().sort(this.comparer(idx));
        this.asc = !this.asc
        if (!this.asc) {
            rows = rows.reverse()
        }
        for (let i = 0; i < rows.length; i++) {
            table.append(rows[i])
        }
    }

    comparer(index) {
        return function (a, b) {
            const valA = $(a).children('td').eq(index).text();
            const valB = $(b).children('td').eq(index).text();
            return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB)
        }
    }

}
