// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

import {subscribeToDiscussionChannel} from "./discussion_channel";

document.addEventListener("turbo:load", function() {
  if (document.body.classList.contains('discussions-index')) {
    subscribeToDiscussionChannel();
  }
});